.data-table {
  line-height: 16px;
  border-collapse: collapse;
  width: 100%;
}

.data-table td,
.data-table th {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px;
}

.data-table th {
  border-top: 1px solid #f0f0f0;
}

.data-table tr {
  border-left: 1px solid #f0f0f0;
}

.data-table tr {
  border-right: 1px solid #f0f0f0;
}

.data-table > thead > tr > th:not(:first-child)::before {
  position: absolute;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.05);
  transform: translateX(-8px) translateY(-2px);
  transition: background-color 0.3s;
  content: "";
}

.data-table tr {
  transition-duration: 0.3s;
  background-color: #fff;
}

.data-table tr:hover {
  background-color: #fafafa;
}

.data-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f8f8f8;
}
